import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  AnalyticsTrackingEventProperties,
  TrackingEvent,
} from '../@types/trackingTypes';
import { useAnalytics } from '../analytics/analyticsContext';
import { VueEventTrackingStrategy } from '../analytics/vueEventTrackingStrategy';
import { actionCreators } from '../store/ActionCreators';
import {
  selectGrandTotalAfterDiscountsInCents,
  selectState,
} from '../store/Selectors';

const useAnalyticsTrackOnce = (event: TrackingEvent) => {
  const triggered = useRef<boolean>(false);
  const analytics = useAnalytics();
  const location = useLocation();
  const globalState = useSelector(selectState);
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const userAgent = navigator.userAgent;
  const dispatch = useDispatch();

  useEffect(() => {
    const isConfirmation = location.pathname.includes('/confirmation/');

    if (!triggered.current && analytics) {
      if (!isConfirmation || globalState.confirmationData) {
        analytics
          .track(event, {
            globalState,
            options: { pathName: location.pathname, grandTotal, userAgent },
          } as AnalyticsTrackingEventProperties)
          .catch((error) => {
            throw new Error(`Analytics tracking error: ${error}`);
          });

        if (globalState.config?.adobeAnalyticsTag) {
          const vueTracking = new VueEventTrackingStrategy();
          vueTracking.processEvent(event, globalState, {
            pathName: location.pathname,
            dataLayerName: 'adobeDataLayer',
          });
        }

        triggered.current = true;
        if (
          event === TrackingEvent.PAYMENT_LANDING ||
          event === TrackingEvent.TICKET_CHECKOUT
        ) {
          dispatch(actionCreators.setDataLayerPopulated(true));
        }
      }
    }
  }, [
    analytics,
    dispatch,
    event,
    globalState,
    location.pathname,
    grandTotal,
    userAgent,
  ]);
};

export default useAnalyticsTrackOnce;
