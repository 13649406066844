import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { SeasonPassMovieShowtime } from '../../../@types/modelTypes';
import { SEASON_PASS_STEPS } from '../../../constants';
import { getDisplayDateAndTime, timeFormat } from '../../../services/Helpers';
import {
  selectContent,
  selectSelectedLanguageCulture,
  selectSelectedSeasonPassSessions,
  selectStep,
} from '../../../store/Selectors';

interface Props {
  showtime: SeasonPassMovieShowtime;
  children: React.ReactNode;
}

const SeasonPassSessionContainer: React.FC<Props> = ({
  showtime,
  children,
}) => {
  const content = useSelector(selectContent);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );
  const { cartSummary } = useSelector(selectContent);
  const step = useSelector(selectStep);

  const selectedTime = selectedSeasonPassSessions.find((s) =>
    showtime.times.some((t) => t.sessionId === s.session.sessionId)
  );

  const isSeatsStep = step === SEASON_PASS_STEPS.SEATS;

  if (isSeatsStep && !selectedTime) {
    return;
  }

  const selectedTimeDisplay =
    isSeatsStep &&
    selectedTime &&
    moment(selectedTime.session.startDateTime)
      .locale(selectedLanguageCulture)
      .format(timeFormat(selectedLanguageCulture));

  const dateDisplay = getDisplayDateAndTime(
    moment(showtime.date).toDate(),
    selectedLanguageCulture
  ).displayDate;

  return (
    <Box sx={{ pb: 4 }} className='show-time'>
      <p>
        {isSeatsStep && (
          <span className='showtime-hourminute'>
            <b>{selectedTimeDisplay}</b>
            {` ${cartSummary.onLabel} `}
          </span>
        )}
        {dateDisplay}
      </p>

      {!!selectedTime?.seats?.length && (
        <p>
          {content.seasonPass.selectedSeatsText}{' '}
          <b>{selectedTime.seats.join(', ')}</b>
        </p>
      )}

      {children}
    </Box>
  );
};

export default SeasonPassSessionContainer;
