/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import CollapsiblePaymentProviderContainer from './CollapsiblePaymentProviderContainer';
import CreditCardPaymentsHeadingContent from './CreditCardPaymentsHeadingContent';
import messages from './intl';
import PaymentProviderSelector from './PaymentProviderSelector';
import ApplePayWorldPay from './providers/applepay/ApplePayWorldPay';
import ZeroBankPayment from './providers/ZeroBankPayment';

import { PaymentProvidersEnum } from '../../../@types/enums';
import { PaymentConfig } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectIsCustomerReadyForPayment,
  selectPaymentProviders,
  selectSelectedPaymentProvider,
  selectIsPaymentPageValidated,
  selectGrandTotalAfterDiscountsInCents,
  selectShowPaymentForm,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyPaymentFormWidget'>;
}

const PaymentForm: React.FC<Props> = ({ widget }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const paymentProviders = useSelector(selectPaymentProviders);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const [creditCardType, setCreditCardType] = useState('');
  const isPageValidated = useSelector(selectIsPaymentPageValidated);
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const showPaymentForm = useSelector(selectShowPaymentForm);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  // sets selectedPaymentProvider when only one option
  useEffect(() => {
    if (paymentProviders.length === 1 && !selectedPaymentProvider) {
      dispatch(actionCreators.setSelectedPaymentProvider(paymentProviders[0]));
    }
  }, [dispatch, paymentProviders, selectedPaymentProvider]);
  const handleValidatePage = () => {
    dispatch(actionCreators.setIsPaymentPageValidated(true));
  };

  // closes all payments when form is invalid
  useEffect(() => {
    if (!isCustomerReadyForPayment) {
      dispatch(actionCreators.setSelectedPaymentProvider(null));
    }
  }, [dispatch, isCustomerReadyForPayment]);

  const handleContainerClick = (paymentProvider: PaymentProvidersEnum) => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    const newPaymentProvider =
      paymentProvider === selectedPaymentProvider ? null : paymentProvider;
    dispatch(actionCreators.setSelectedPaymentProvider(newPaymentProvider));
  };

  const renderApplePay = paymentProviders.includes(
    PaymentProvidersEnum.WORLDPAYAPPLEPAY
  );
  const hasMultipleProviders = paymentProviders.length > 1;
  const paymentConfig: PaymentConfig = {
    hideVisa: widget.shape?.hideVisa,
    hideMastercard: widget.shape?.hideMastercard,
    hideAmex: widget.shape?.hideAmex,
    hideDiscover: widget.shape?.hideDiscover,
    hideMaestro: widget.shape?.hideMaestro,
    showApplePay: widget.shape?.showApplePay,
    showGooglePay: widget.shape?.showGooglePay,
  };
  if (!isReadyForPayment || !showPaymentForm) return;

  return (
    <ContainedRow>
      <div className='payment-container bordered'>
        {grandTotal === 0 && (
          <ContainedRow>
            <ZeroBankPayment
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
            />
          </ContainedRow>
        )}

        {grandTotal > 0 && (
          <>
            <h2 sx={{ textAlign: 'center' }}>
              {formatMessage(messages.paymentDetailsSubtitle)}
            </h2>
            <div>{formatMessage(messages.paymentInstructionsRichText)}</div>
            {renderApplePay && <ApplePayWorldPay />}

            {paymentProviders
              .filter((x) => x != PaymentProvidersEnum.WORLDPAYAPPLEPAY)
              .map((paymentProvider, index: number) => (
                <Box
                  className={classNames(
                    'credit-card-payments-container',
                    hasMultipleProviders && 'multi'
                  )}
                  key={`payment_form_${index}`}
                  sx={{
                    mt: 2,
                    '&.multi': {
                      '.bordered-collapse': {
                        backgroundColor: 'websiteBackground',
                      },
                    },
                    svg: {
                      '.plus-minus': {
                        fill: 'boxBorderColor',
                      },
                    },
                  }}
                >
                  <CollapsiblePaymentProviderContainer
                    hasOnePaymentOption={!hasMultipleProviders}
                    key={`payment_form_${index}`}
                    setShow={() => handleContainerClick(paymentProvider)}
                    show={selectedPaymentProvider === paymentProvider}
                    headingContent={
                      <CreditCardPaymentsHeadingContent
                        paymentProvider={paymentProvider}
                        creditCardType={creditCardType}
                        hasOnePaymentOption={!hasMultipleProviders}
                        paymentConfig={paymentConfig}
                      />
                    }
                    isShownClassName='highlight-border'
                    paymentProvider={paymentProvider}
                  >
                    <PaymentProviderSelector
                      isPageValidated={isPageValidated}
                      handleValidatePage={handleValidatePage}
                      setCreditCardType={setCreditCardType}
                      paymentProvider={paymentProvider}
                      paymentConfig={paymentConfig}
                    />
                  </CollapsiblePaymentProviderContainer>
                </Box>
              ))}
          </>
        )}
      </div>
    </ContainedRow>
  );
};

export default PaymentForm;
