import React, { FC, memo } from 'react';

import CustomHtmlDiv from '../../common/customHtmlDiv/CustomHtmlDiv';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyThirdPartyPlaceholderWidget'>;
};

const ThirdPartyPlaceholder: FC<Props> = ({ widget }) => {
  const { shape } = widget;

  return shape?.placeholderId ? (
    <CustomHtmlDiv id={shape.placeholderId} />
  ) : null;
};

export default memo(ThirdPartyPlaceholder);
