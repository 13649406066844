import * as Sentry from '@sentry/react';

const loadSentry = (sentryDsnKey) => {
  Sentry.init({
    dsn: sentryDsnKey,
    integrations: [],
    tracesSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || '0.1'
    ),
    replaysSessionSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE || '0.1'
    ),
    replaysOnErrorSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE || '1.0'
    ),
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'ResizeObserver loop completed with undelivered notifications.',
      'Error getting Turnstile token: No response received',
    ],
    beforeSend(event) {
      const userAgent = event.request?.headers?.['User-Agent'] || '';
      // Facebook in-app browser v.28.0.0 (filtering bot-caused errors to declutter Sentry)
      const fbPattern = /FBAV\/28\.0\.0/;
      if (userAgent && fbPattern.test(userAgent)) {
        return null;
      }

      if (event.exception?.values) {
        // Filtering insertBefore error caused by external DOM-manupulation that crashes React DOM
        for (let ex of event.exception.values) {
          if (
            ex.type === 'NotFoundError' &&
            ex.value?.includes("Failed to execute 'insertBefore' on 'Node'")
          ) {
            return null;
          }

          if (
            ex.type === 'NotFoundError' &&
            ex.value?.includes('The object can not be found here.')
          ) {
            return null;
          }

          if (
            ex.mechanism &&
            ex.mechanism.type === 'onunhandledrejection' &&
            ex.value
          ) {
            if (ex.value.includes('XhrError') || ex.value.includes('Timeout')) {
              return null;
            }
          }
        }

        // Filter out "TypeError: Load failed"
        for (let ex of event.exception.values) {
          if (ex.type === 'TypeError' && ex.value?.includes('Load failed')) {
            return null;
          }
        }

        // Filter out "TypeError: Failed to fetch"
        for (let ex of event.exception.values) {
          if (
            ex.type === 'TypeError' &&
            ex.value?.includes('Failed to fetch')
          ) {
            return null;
          }
        }

        const turnstileErrorCodes = [
          // Initialization problems
          '100040',
          // Invalid parameters. Potentially bots.
          '106010',
          '106030',
          '106040',
          // Domain not allowed
          '110200',
          // Challenge timeout
          '110600',
          // Generic client execution error. Potentially bots.
          '300010',
          '300030',
          '300031',
          // Challenge failed. Potentially bots.
          '600010',
        ];

        // Filter out Turnstile errors
        for (let ex of event.exception.values) {
          if (
            ex.type === 'TurnstileError' &&
            turnstileErrorCodes.some((code) =>
              ex.value?.includes(`Error: ${code}`)
            )
          ) {
            return null;
          }
        }

        // Filter out Google Translate errors
        for (let ex of event.exception.values) {
          if (
            ex.type === 'TypeError' &&
            ex.value?.includes("undefined is not an object (evaluating 'a.L')")
          ) {
            return null;
          }
        }

        // Filter GTM errors
        for (let ex of event.exception.values) {
          if (
            ex.type === 'TypeError' &&
            ex.value?.includes(
              "Cannot read properties of null (reading 'addEventListener')"
            )
          ) {
            return null;
          }
        }
      }

      return event;
    },
  });
};

export default loadSentry;
