import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';

import { selectBookingData, selectCartSummary } from '../../../store/Selectors';
import Widget from '../index';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyConditionalWidget'>;
};

export const Conditional: FC<Props> = ({ widget }) => {
  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);

  const selectedAuthenticationStatus = widget.shape?.isUserAuthenticated;
  const selectedTheaters = widget.shape?.theaters;
  const selectedMovies = widget.shape?.movies;

  const currentAuthenticationStatus = bookingData?.isUserValidated;
  const currentTheater = bookingData?.boxofficeCinemaId;
  const currentMovie = cartSummary?.dbzFilmId?.toString();

  const shouldShowForCurrentAuthenticationStatus: boolean =
    typeof selectedAuthenticationStatus === 'boolean'
      ? selectedAuthenticationStatus === currentAuthenticationStatus
      : true;

  const shouldShowForCurrentTheater: boolean = selectedTheaters?.length
    ? selectedTheaters.some((theater) => theater.rawId === currentTheater)
    : true;

  const shouldShowForCurrentMovie: boolean = selectedMovies?.length
    ? selectedMovies.some((movie) => movie.rawId === currentMovie)
    : true;

  return (
    <div>
      {shouldShowForCurrentAuthenticationStatus &&
        shouldShowForCurrentTheater &&
        shouldShowForCurrentMovie &&
        widget.shape?.widgets?.map((widget) => {
          return <Widget key={widget?.id} widget={widget} />;
        })}
    </div>
  );
};

export default memo(Conditional);
