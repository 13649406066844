import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Turnstile } from '@marsidev/react-turnstile';
import type { TurnstileInstance } from '@marsidev/react-turnstile';
import { useSelector } from 'react-redux';

import {
  selectTurnstileSiteKey,
  selectShouldUseTurnstile,
} from '../store/Selectors';

export interface TurnstileContext {
  getToken: () => Promise<string | null>;
  resetToken: () => void;
}

export interface ProviderProps {
  children: React.ReactNode;
}

const TurnstileContextInstance = createContext<TurnstileContext | null>(null);

export const TurnstileProvider: React.FC<ProviderProps> = ({ children }) => {
  const turnstileRef = useRef<TurnstileInstance>(null);
  const [siteKey, setSiteKey] = useState('');

  const shouldUseTurnstile = useSelector(selectShouldUseTurnstile);
  const turnstileSiteKey = useSelector(selectTurnstileSiteKey);

  useEffect(() => {
    if (turnstileSiteKey && !siteKey) {
      setSiteKey(turnstileSiteKey);
    }
  }, [siteKey, turnstileSiteKey]);

  const turnstileEnabled = shouldUseTurnstile && !!siteKey;

  const turnstileContextInstance: TurnstileContext = useMemo(
    () => ({
      getToken: async () => {
        if (!turnstileRef?.current) {
          return null;
        }

        if (turnstileRef.current.isExpired()) {
          turnstileRef.current.reset();
        }

        return await turnstileRef.current
          .getResponsePromise()
          .catch((error) => {
            throw new Error(`Error getting Turnstile token: ${error}`);
          });
      },
      resetToken: () => {
        turnstileRef?.current?.reset();
      },
    }),
    []
  );

  return (
    <TurnstileContextInstance.Provider value={turnstileContextInstance}>
      {children}

      {turnstileEnabled && (
        <Turnstile
          ref={turnstileRef}
          options={{ size: 'invisible' }}
          siteKey={siteKey}
        />
      )}
    </TurnstileContextInstance.Provider>
  );
};

export const useTurnstile = () => {
  return useContext(TurnstileContextInstance) ?? null;
};
