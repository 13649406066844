import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { GlobalState } from '../../../../@types/modelTypes';
import {
  selectContent,
  selectDisplayPrice,
  selectDonation,
} from '../../../../store/Selectors';
import RichText from '../../../common/richtext/RichText';

const GiftCardDonationNotIncludedMessage = () => {
  const donation = useSelector(selectDonation);
  const content = useSelector(selectContent);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, donation)
  );

  if (!donation || !content.payment.giftCardDonationNotIncludedRichText) {
    return null;
  }

  return (
    <Box
      className='info-container'
      data-testid='gift-card-donation-message'
      sx={{ my: 2, p: 4 }}
    >
      <RichText
        text={content.payment.giftCardDonationNotIncludedRichText.replace(
          '##DonationAmount##',
          priceToDisplay
        )}
      />
    </Box>
  );
};

export default GiftCardDonationNotIncludedMessage;
